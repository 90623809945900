<template>
  <div
    class="container bg align-items-center justify-content-center bg- pb-5 mt-4"
    style="min-height: 40vh"
  >
    <div class="row">
      <div class="col-sm-12">
        <h5>
          Bienvenue <span class="text-primary">{{ entreprise.raisonSocial }}</span>
        </h5>
        <PMessage severity="warn" v-if="entreprise.tauxRemplissageProfil < 10"
          >Vous n'avez pas encore constitué votre dossier.
          <p-button class="p-button p-button-sm ms-2" @click.prevent="gotoEditDossier"
            >Constituer mon dossier</p-button
          ></PMessage
        >

        <div
          v-if="entreprise.tauxRemplissageProfil < 10"
          class="d-flex flex-column align-items-center justify-content-center bg-light pa-5 ma-5"
          style="min-height: 40vh"
        >
          <div class="mb-3 text-muted">
            Vous n'avez pas encore constitué votre dossier.
          </div>
          <a @click="gotoEditDossier" class="btn btn-outline-primary"
            >Constituer mon dossier</a
          >
        </div>
        <div class="statistic-box" v-if="entreprise.tauxRemplissageProfil >= 50">
          <EntrepriseSelfStatistic ref="activiteStatistic" class="mt-3 mb-2" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { API_URL } from "../../../config/api-config";
import EntrepriseSelfStatistic from "../../../components/statistic/entreprise/EntrepriseSelfStatistic.vue";

export default {
  components: {
    EntrepriseSelfStatistic,
  },
  data() {
    return {
      hasCv: false,
      entreprise: {},
      API_URL,
    };
  },
  computed: {
    ...mapGetters({
      dossier: "demandeur/demandeur",
    }),
    de() {
      console.log(this.dossier);
      return this.demandeur;
    },
  },
  watch: {},
  async created() {
    await this.getProfile();
  },
  methods: {
    ...mapActions({
      updateDemandeurCv: "demandeur/updateDemandeurCv",
      fetchMonProfil: "entreprise/fetchMonProfil",
    }),
    getProfile() {
      this.fetchMonProfil()
        .then((result) => {
          this.entreprise = {
            ...this.entreprise,
            ...result,
          };
          console.log(this.entreprise);
        })
        .catch((err) => {
          this.$toast.error(`Echec de chargement du dossier`, {
            position: "bottom-right",
            duration: 35000,
          });
        });
    },
    gotoEditDossier() {
      this.$router.push({
        name: "espace.entreprise.dossier.edit",
        query: { edit: true },
      });
    },
  },
};
</script>
